import { render, staticRenderFns } from "./merchantSingleInfo.html?vue&type=template&id=0b741d48&scoped=true&"
import script from "./merchantSingleInfo.js?vue&type=script&lang=js&"
export * from "./merchantSingleInfo.js?vue&type=script&lang=js&"
import style0 from "./merchantSingleInfo.scss?vue&type=style&index=0&id=0b741d48&scoped=true&lang=scss&"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "0b741d48",
  null
  
)

export default component.exports