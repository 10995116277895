import { mapGetters } from "vuex";
import * as types from "../../../../store/types";
import merchantListService from "../../../../services/merchantListService";
import $renderDate from "./../../../../mixins/renderDate";
import rolesService from "../../../../services/rolesService";
import { ASSETS_URL } from "../../../../services/base";

export default {
  name: 'merchant-single-info',
  mixins: [$renderDate],
  components: {},
  props: [],
  data() {
    return {
      merchantsingleData: "",
      statuses: "",
      merchantid: "",
      adminId: "",
      roles: "",
      baseURL: ASSETS_URL,
    }
  },
  computed: {
    ...mapGetters({
      userInfo: types.GET_USER_INFO,
    }),
  },
  mounted() {
    this.getRoles();
    this.getStatusFunc();
    this.getSingleMerchantData();
    this.merchantid = "merchant = " + this.$route.query.merchantid;
    this.adminId = "admin = " + localStorage.getItem("bitrahAdminAccessToken");
  },
  methods: {
    //get single merchant data
    getSingleMerchantData() {
      merchantListService
        .getSingleMerchantData(
          this.$route.query.merchantid,
          localStorage.getItem("bitrahAdminAccessToken")
        )
        .then((response) => {
          this.merchantsingleData = response.data;
        });
    },
    // get status
    getStatusFunc() {
      merchantListService.getstatus().then((response) => {
        this.statuses = response.data;
      });
    },
    // change status
    changeStatus(type, status, id) {
      merchantListService
        .changeStatus(
          type,
          status,
          id,
          localStorage.getItem("bitrahAdminAccessToken"),
        )
        .then((response) => {
          if (response.data) {
            if (response.data === true) {
              this.getStatusFunc();
              this.getSingleMerchantData();
              this.$bvToast.toast(this.$i18n.t("toast.succesfullOperation"), {
                title: this.$i18n.t("toast.successTitle"),
                noCloseButton: true,
                variant: "custom",
                bodyClass:
                  "successFontColor rtl text-right font-weight-bold py-3",
                headerClass:
                  "successBackgroundColor successFontColor  rtl text-right",
                solid: false,
              });
            }
            else {
              this.getMerchantList();
              this.$bvToast.toast(this.$i18n.t("toast.failedOperation"), {
                title: this.$i18n.t("toast.errorTitle"),
                noCloseButton: true,
                variant: "custom",
                bodyClass:
                  "dangerBackgroundColor dangerFontColor rtl text-right",
                headerClass:
                  "dangerBackgroundColor dangerFontColor  rtl text-right",
                solid: false,
              });
            }

          } else {
            this.getMerchantList();
            this.$bvToast.toast(this.$i18n.t("toast.failedOperation"), {
              title: this.$i18n.t("toast.errorTitle"),
              noCloseButton: true,
              variant: "custom",
              bodyClass:
                "dangerBackgroundColor dangerFontColor rtl text-right",
              headerClass:
                "dangerBackgroundColor dangerFontColor  rtl text-right",
              solid: false,
            });
          }
        }).catch((Response) => {
          if (Response.data) {
            this.$bvToast.toast(Response.data.message, {
              title: this.$i18n.t("toast.errorTitle"),
              noCloseButton: true,
              variant: "custom",
              bodyClass:
                "dangerBackgroundColor dangerFontColor rtl text-right",
              headerClass:
                "dangerBackgroundColor dangerFontColor rtl text-right",
              solid: false,
            });
          }
        });
    },
    changeRole(isAdmin) {
      merchantListService
        .changeRole(
          isAdmin,
          this.merchantsingleData.merchant.id,
          localStorage.getItem("bitrahAdminAccessToken")
        )
        .then((response) => {
          if (response)
            this.getSingleMerchantData();
        }).catch((Response) => {
          if (Response.data) {
            this.$bvToast.toast(Response.data.message, {
              title: this.$i18n.t("toast.errorTitle"),
              noCloseButton: true,
              variant: "custom",
              bodyClass:
                "dangerBackgroundColor dangerFontColor rtl text-right",
              headerClass:
                "dangerBackgroundColor dangerFontColor  rtl text-right",
              solid: false,
            });
          }
        });
    },
    getRoles() {
      rolesService.getRoles().then((Response) => {
        this.roles = Response.data;
      });
    },
    checkIsRole(roleName) {
      for (let i = 0; i < this.merchantsingleData.merchant.roles.length; i++) {
        if (this.merchantsingleData.merchant.roles[i].role === roleName) return true;
      }
      return false;
    },
    addRole(id, role) {
      rolesService.employeeRolesAdd(
        id,
        role,
        this.userInfo.merchantId
      ).then((Response) => {
        if (Response.data) {
          this.getSingleMerchantData();
        }
      }).catch((Response) => {
        if (Response.data) {
          this.$bvToast.toast(Response.data.message, {
            title: this.$i18n.t("toast.errorTitle"),
            noCloseButton: true,
            variant: "custom",
            bodyClass:
              "dangerBackgroundColor dangerFontColor rtl text-right",
            headerClass:
              "dangerBackgroundColor dangerFontColor  rtl text-right",
            solid: false,
          });
        }
      });
    },
    removeRole(id, role) {
      rolesService.employeeRolesRemove(
        id,
        role,
        this.userInfo.merchantId
      ).then((Response) => {
        if (Response.data) {
          this.getSingleMerchantData();
        }
      }).catch((Response) => {
        if (Response.data) {
          this.$bvToast.toast(Response.data.message, {
            title: this.$i18n.t("toast.errorTitle"),
            noCloseButton: true,
            variant: "custom",
            bodyClass:
              "dangerBackgroundColor dangerFontColor rtl text-right",
            headerClass:
              "dangerBackgroundColor dangerFontColor  rtl text-right",
            solid: false,
          });
        }
      });
    },
    removeIpAddress(id) {
      merchantListService.removeIpAddress(
        id,
        this.userInfo.merchantId
      ).then((response) => {
        if (response.data) {
          this.getSingleMerchantData();
        }
      }).catch((Response) => {
        if (Response.data) {
          this.$bvToast.toast(Response.data.message, {
            title: this.$i18n.t("toast.errorTitle"),
            noCloseButton: true,
            variant: "custom",
            bodyClass:
              "dangerBackgroundColor dangerFontColor rtl text-right",
            headerClass:
              "dangerBackgroundColor dangerFontColor  rtl text-right",
            solid: false,
          });
        }
      });

    },
  }
}


